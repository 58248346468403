import React, {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import LineChartWrapper from "../../common/LineChartWrapper";
import ChartTableToggle from "../../common/ChartTableToggle";
import {Col, Row} from "react-bootstrap";
import {ErrorBoundary} from "react-error-boundary";
import {
    addTotals,
    buildColumnsFromData,
    convertToMomentTz,
    getDateFormatByAggregation
} from "../../commons";
import BasicTable from "../../common/BasicTable";
import AreaChartWrapper from "../../common/AreaChartWrapper";


function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function RegulatorPlantsView({
                              viewName,
                              typeName,
                              aggregation,
                              simulationId,
                              simulationInitialValues,
                              simulation,
                              yAxisUnit
                          }){

    const [data, setData] = useState([]);
    const [tabMode, setTabMode] = useState("charts");

    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/investment/' + simulationId + '/settings/' + simulationInitialValues + '/results/'+typeName+'?'
                + 'aggregation='+ aggregation))
            .then(res => res.json()).then(data => {
                setData(
                    data["data"].map(d => {
                        let item = {
                            time: d.entryDate,
                            ...JSON.parse(d["data"])
                        }
                        if(typeName === 'captureGridPrice'){
                            item["captured grid price"] = item["VAL_0"];
                            delete item["VAL_0"];
                        }
                        if(viewName === 'Hydrogen Production (Tons)'){
                            item["H2"] = item["H2"] / 1000;
                        }
                        if(typeName === 'production_by_destination_percentage'){
                            Object.keys(item).forEach(k =>{
                                if(k !== 'time'){
                                    item[k] = item[k] * 100;
                                }
                            })
                        }
                        return item;
                    })
                )
        });

    }, [simulationId, aggregation, typeName, simulationInitialValues, simulation.configuration.plants, viewName]);

    const tableData = [...data].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation("Month"))}));

    const productionByPlantName = "Monthly production by plant (MWh)";
    let chart = <LineChartWrapper data={data}
                                  title={viewName}
                                  brushKey="time"
                                  xAxisKey="time"
                                  aggregation={"Date"}
                                  decimals={0}
                                  isDomainPercentage={typeName === 'production_by_destination_percentage'}
                                  yAxisUnit={yAxisUnit ? yAxisUnit : "€/MWh"}/>
    if(productionByPlantName === viewName){
        chart = <AreaChartWrapper data={data}
                                  title={viewName}
                                  brushKey="time"
                                  xAxisKey="time"
                                  aggregation={"Date"}
                                  decimals={0}
                                  yAxisUnit={yAxisUnit ? yAxisUnit : "€/MWh"}/>
    }

    const tabsByMode = tabMode === "charts" ? (
        chart
    ) : (
        <div style={{marginTop: "15px"}}>
            <BasicTable title={viewName} data={addTotals(tableData)} columns={buildColumnsFromData(addTotals(tableData))} options={{
                fixedHeaderScrollHeight: "350px"
            }}/>;
        </div>
    );


    return data.length > 0 ? (
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
            >
                <div className="recharts-component">
                    <ChartTableToggle mode={tabMode} updateTabMode={setTabMode} updateSelectedTab={()=>{}}/>
                    <Row>
                        <Col sm={12}>
                            {tabsByMode}
                        </Col>
                    </Row>
                </div>
            </ErrorBoundary>
    ) : <h3>Loading...</h3>
}

export default RegulatorPlantsView;